@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-bg-primary: theme('colors.primary.700');
    --color-bg-primary-hover: theme('colors.primary.900');
    --color-bg-primary-pressed: theme('colors.black.900');
    --color-bg-primary-shade: theme('colors.black.900');
    --color-bg-secondary: theme('colors.black.50');
    --color-bg-secondary-hover: theme('colors.black.200');
    --color-bg-secondary-pressed: theme('colors.black.300');
    --color-bg-secondary-shade: theme('colors.black.200');
    --color-bg-secondary-neutral: theme('colors.neutral.50');
    --color-bg-secondary-bold: theme('colors.black.100');
    --color-bg-secondary-selected: theme('colors.primary.700');
    --color-bg-neutral: theme('colors.primary.50');
    --color-bg-neutral-alwaysLight: theme('colors.primary.50');
    --color-bg-neutral-alwaysDark: theme('colors.primary.700');
    --color-bg-neutral-alwaysDark-hover: theme('colors.primary.900');
    --color-bg-neutral-alwaysDark-pressed: theme('colors.black.900');
    --color-bg-inverse: theme('colors.black.900');
    --color-bg-brand: theme('colors.brand.500');
    --color-bg-brand-hover: theme('colors.brand.700');
    --color-bg-brand-pressed: theme('colors.brand.900');
    --color-bg-brand-shade: theme('colors.brand.900');
    --color-bg-info: theme('colors.information.50');
    --color-bg-info-bold: theme('colors.information.200');
    --color-bg-notice: theme('colors.warning.50');
    --color-bg-notice-bold: theme('colors.warning.500');
    --color-bg-negative: theme('colors.danger.50');
    --color-bg-negative-hover: theme('colors.danger.700');
    --color-bg-negative-pressed: theme('colors.danger.900');
    --color-bg-negative-shade: theme('colors.danger.900');
    --color-bg-negative-bold: theme('colors.danger.500');
    --color-bg-positive: theme('colors.success.50');
    --color-bg-positive-bold: theme('colors.success.500');
    --color-bg-accent-2: theme('colors.secondary.200');
    --color-bg-accent-3: theme('colors.information.500');
    --color-bg-accent-4: theme('colors.success.500');
    --color-bg-accent-5: theme('colors.warning.500');
    --color-bg-accent-6: theme('colors.brown.500');
    --color-bg-accent-7: theme('colors.highlight.500');
    --color-border-regular: theme('colors.black.200');
    --color-border-bold: theme('colors.black.300');
    --color-border-disabled: theme('colors.neutral.200');
    --color-border-inverse: theme('colors.primary.50');
    --color-border-selected: theme('colors.black.900');
    --color-border-menu: theme('colors.primary.700');
    --color-border-brand: theme('colors.brand.700');
    --color-border-focus: theme('colors.information.200');
    --color-border-info: theme('colors.information.500');
    --color-border-notice: theme('colors.warning.500');
    --color-border-negative: theme('colors.danger.500');
    --color-border-positive: theme('colors.success.500');
    --color-fg-primary: theme('colors.black.900');
    --color-fg-secondary: theme('colors.black.600');
    --color-fg-tertiary: theme('colors.black.400');
    --color-fg-primary-inverse: theme('colors.primary.50');
    --color-fg-secondary-inverse: theme('colors.neutral.100');
    --color-fg-tertiary-inverse: theme('colors.neutral.300');
    --color-fg-disabled: theme('colors.black.300');
    --color-fg-alwaysLight: theme('colors.primary.50');
    --color-fg-alwaysDark: theme('colors.primary.900');
    --color-fg-brand: theme('colors.brand.500');
    --color-fg-brand-bold: theme('colors.brand.900');
    --color-fg-link-light: theme('colors.information.200');
    --color-fg-link: theme('colors.information.700');
    --color-fg-link-hover: theme('colors.information.900');
    --color-fg-link-pressed: theme('colors.black.700');
    --color-fg-info: theme('colors.information.500');
    --color-fg-info-bold: theme('colors.information.700');
    --color-fg-notice: theme('colors.warning.700');
    --color-fg-notice-bold: theme('colors.warning.900');
    --color-fg-negative: theme('colors.danger.700');
    --color-fg-negative-bold: theme('colors.danger.900');
    --color-fg-positive: theme('colors.success.500');
    --color-fg-positive-bold: theme('colors.success.700');
    --color-fg-highlight: theme('colors.highlight.500');
    --color-surface-l0: theme('colors.primary.900');
    --color-surface-l1: theme('colors.primary.700');
    --color-surface-l2: theme('colors.primary.50');
    --color-surface-l3: theme('colors.primary.500');
    --color-surface-l4: theme('colors.primary.200');
    --color-surface-mp: theme('colors.primary.200');
    --color-surface-blur: theme('colors.primary.200');
    --filter-color-fg-primary: invert(8%) sepia(90%) saturate(1339%) hue-rotate(185deg) brightness(92%) contrast(105%);
    --filter-color-fg-secondary: invert(38%) sepia(9%) saturate(1169%) hue-rotate(169deg) brightness(90%) contrast(85%);
    --filter-color-fg-primary-inverse: invert(100%) sepia(3%) saturate(3421%) hue-rotate(331deg) brightness(130%)
      contrast(101%);
    --filter-color-fg-info-bold: invert(38%) sepia(50%) saturate(710%) hue-rotate(170deg) brightness(91%) contrast(87%);
    --filter-color-fg-positive: invert(44%) sepia(28%) saturate(754%) hue-rotate(91deg) brightness(95%) contrast(92%);
    --filter-color-fg-positive-bold: invert(29%) sepia(26%) saturate(1202%) hue-rotate(91deg) brightness(97%)
      contrast(89%);
    --filter-color-fg-disabled: invert(82%) sepia(17%) saturate(162%) hue-rotate(165deg) brightness(94%) contrast(86%);
    --filter-color-fg-link: invert(35%) sepia(76%) saturate(495%) hue-rotate(170deg) brightness(93%) contrast(87%);
    --filter-color-fg-alwaysLight: invert(96%) sepia(100%) saturate(0%) hue-rotate(174deg) brightness(104%)
      contrast(102%);
    --filter-color-bg-accent-5: invert(74%) sepia(47%) saturate(661%) hue-rotate(355deg) brightness(108%) contrast(92%);
    --filter-color-fg-notice-bold: invert(37%) sepia(14%) saturate(5078%) hue-rotate(24deg) brightness(97%)
      contrast(97%);
  }

  .dark {
    --color-bg-primary: theme('colors.primary.50');
    --color-bg-primary-hover: theme('colors.primary.500');
    --color-bg-primary-pressed: theme('colors.black.300');
    --color-bg-primary-shade: theme('colors.black.100');
    --color-bg-secondary: theme('colors.black.700');
    --color-bg-secondary-hover: theme('colors.black.600');
    --color-bg-secondary-pressed: theme('colors.black.500');
    --color-bg-secondary-shade: theme('colors.black.600');
    --color-bg-secondary-neutral: theme('colors.black.700');
    --color-bg-secondary-bold: theme('colors.black.800');
    --color-bg-secondary-selected: theme('colors.primary.50');
    --color-bg-neutral: theme('colors.black.800');
    --color-bg-neutral-alwaysLight: theme('colors.primary.50');
    --color-bg-neutral-alwaysDark: theme('colors.black.800');
    --color-bg-neutral-alwaysDark-hover: theme('colors.primary.900');
    --color-bg-neutral-alwaysDark-pressed: theme('colors.black.800');
    --color-bg-inverse: theme('colors.primary.50');
    --color-bg-brand: theme('colors.brand.500');
    --color-bg-brand-hover: theme('colors.brand.700');
    --color-bg-brand-pressed: theme('colors.brand.900');
    --color-bg-brand-shade: theme('colors.brand.900');
    --color-bg-info: theme('colors.information.200');
    --color-bg-info-bold: theme('colors.information.700');
    --color-bg-notice: theme('colors.warning.200');
    --color-bg-notice-bold: theme('colors.warning.700');
    --color-bg-negative: theme('colors.danger.200');
    --color-bg-negative-hover: theme('colors.danger.500');
    --color-bg-negative-pressed: theme('colors.danger.900');
    --color-bg-negative-shade: theme('colors.danger.900');
    --color-bg-negative-bold: theme('colors.danger.700');
    --color-bg-positive: theme('colors.success.200');
    --color-bg-positive-bold: theme('colors.success.700');
    --color-bg-accent-2: theme('colors.secondary.200');
    --color-bg-accent-3: theme('colors.information.500');
    --color-bg-accent-4: theme('colors.success.500');
    --color-bg-accent-5: theme('colors.warning.500');
    --color-bg-accent-6: theme('colors.brown.500');
    --color-bg-accent-7: theme('colors.highlight.500');
    --color-border-regular: theme('colors.black.600');
    --color-border-bold: theme('colors.black.600');
    --color-border-disabled: theme('colors.black.600');
    --color-border-inverse: theme('colors.primary.900');
    --color-border-selected: theme('colors.black.50');
    --color-border-menu: theme('colors.black.600');
    --color-border-brand: theme('colors.brand.500');
    --color-border-focus: theme('colors.information.500');
    --color-border-info: theme('colors.information.500');
    --color-border-notice: theme('colors.warning.200');
    --color-border-negative: theme('colors.danger.200');
    --color-border-positive: theme('colors.success.200');
    --color-fg-primary: theme('colors.primary.50');
    --color-fg-secondary: theme('colors.black.400');
    --color-fg-tertiary: theme('colors.black.300');
    --color-fg-primary-inverse: theme('colors.black.900');
    --color-fg-secondary-inverse: theme('colors.black.500');
    --color-fg-tertiary-inverse: theme('colors.black.300');
    --color-fg-disabled: theme('colors.black.600');
    --color-fg-alwaysLight: theme('colors.primary.50');
    --color-fg-alwaysDark: theme('colors.primary.900');
    --color-fg-brand: theme('colors.brand.200');
    --color-fg-brand-bold: theme('colors.brand.900');
    --color-fg-link-light: theme('colors.information.700');
    --color-fg-link: theme('colors.information.200');
    --color-fg-link-hover: theme('colors.information.500');
    --color-fg-link-pressed: theme('colors.information.700');
    --color-fg-info: theme('colors.information.200');
    --color-fg-info-bold: theme('colors.information.700');
    --color-fg-notice: theme('colors.warning.200');
    --color-fg-notice-bold: theme('colors.warning.700');
    --color-fg-negative: theme('colors.danger.200');
    --color-fg-negative-bold: theme('colors.danger.500');
    --color-fg-positive: theme('colors.success.200');
    --color-fg-positive-bold: theme('colors.success.700');
    --color-fg-highlight: theme('colors.highlight.200');
    --color-surface-l0: theme('colors.black.950');
    --color-surface-l1: theme('colors.black.900');
    --color-surface-l2: theme('colors.black.900');
    --color-surface-l3: theme('colors.black.800');
    --color-surface-l4: theme('colors.black.700');
    --color-surface-mp: theme('colors.black.800');
    --color-surface-blur: theme('colors.black.700');
    --filter-color-fg-primary: invert(100%) sepia(3%) saturate(3421%) hue-rotate(331deg) brightness(130%) contrast(101%);
    --filter-color-fg-secondary: invert(66%) sepia(9%) saturate(247%) hue-rotate(166deg) brightness(99%) contrast(91%);
    --filter-color-fg-primary-inverse: invert(8%) sepia(90%) saturate(1339%) hue-rotate(185deg) brightness(92%)
      contrast(105%);
    --filter-color-fg-info-bold: invert(38%) sepia(50%) saturate(710%) hue-rotate(170deg) brightness(91%) contrast(87%);
    --filter-color-fg-positive: invert(94%) sepia(11%) saturate(346%) hue-rotate(88deg) brightness(97%) contrast(94%);
    --filter-color-fg-positive-bold: invert(93%) sepia(14%) saturate(281%) hue-rotate(86deg) brightness(94%)
      contrast(98%);
    --filter-color-fg-disabled: invert(38%) sepia(9%) saturate(1169%) hue-rotate(169deg) brightness(90%) contrast(85%);
    --filter-color-fg-link: invert(82%) sepia(18%) saturate(449%) hue-rotate(172deg) brightness(98%) contrast(96%);
    --filter-color-fg-alwaysLight: invert(96%) sepia(100%) saturate(0%) hue-rotate(174deg) brightness(104%)
      contrast(102%);
    --filter-color-bg-accent-5: invert(74%) sepia(47%) saturate(661%) hue-rotate(355deg) brightness(108%) contrast(92%);
    --filter-color-fg-notice-bold: invert(65%) sepia(56%) saturate(654%) hue-rotate(7deg) brightness(87%) contrast(96%);
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .bg-gradient {
    background-image: -webkit-linear-gradient(180deg, rgba(245, 238, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-image: -moz-linear-gradient(180deg, rgba(245, 238, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-image: linear-gradient(180deg, rgba(245, 238, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-repeat: no-repeat;
    background-size: 100% 600px;
  }

  .bg-gradient-smt {
    background-image: -webkit-linear-gradient(180deg, rgba(249, 246, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-image: -moz-linear-gradient(180deg, rgba(249, 246, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-image: linear-gradient(180deg, rgba(249, 246, 236, 1) 0%, rgba(255, 255, 255, 1) 53%);
    background-repeat: no-repeat;
    background-size: 100% 600px;
  }
  .spin-button-none::-webkit-inner-spin-button {
    display: none;
  }
  .bg-swish-gradient {
    background-image: linear-gradient(130.07deg, #c4d301 0%, #039f3c 78.07%);
  }
}

@layer base {
  html,
  body {
    font-family: Brown, Gibson, Roboto, sans-serif;
    font-feature-settings: 'ss01', 'ss02';
  }

  a {
    @apply text-information-700;
  }

  .serp-banner:not(:first-of-type) {
    @apply mt-0;
  }
}

@layer components {
  .container {
    @apply mx-auto max-w-screen-xl;
  }
  .container-screen {
    @apply max-w-screen-2xl;
  }
}
