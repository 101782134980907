.container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.3s, padding 0.3s;

  &:not(.expanded) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
