@import '@/styles/common/mixins';

.blogPageContent {
  margin-top: 40px !important;
  margin-bottom: 40px;

  p {
    margin: 16px 0;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    margin: 16px 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 16px 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 16px 0;
  }

  strong {
    font-weight: 600;
  }

  ul {
    margin: 20px 0px;
    padding: 0px;
    list-style-type: none;
  }

  li {
    padding: 8px 0;
  }

  a {
    @apply text-information-700 underline;
  }

  table {
    background-color: #eaf0f6;
    font-size: 14px;

    @include md-screen-up($screen-md) {
      width: 100%;
      table-layout: fixed;
    }
  }

  table,
  td {
    border: 1px solid #c1c3c6;
  }

  td {
    text-align: left;
    padding: 8px;
  }

  td strong {
    @include md-screen-up($screen-md) {
      font-size: 16px;
    }
  }

  &.supportContent {
    li {
      padding-left: 32px;
    }
  }

  &.newsletterContent {
    max-width: 700px;
  }

  &.termsContent {
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 16px 0;
    }

    p {
      margin-bottom: 16px;
      margin-top: 0;
      font-size: 15px;
      white-space: pre-wrap;
    }

    ul {
      list-style-type: disc;
      margin-top: 0;
      margin-left: 32px;
      font-size: 15px;
    }
  }
  &.privacyPolicyContent {
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      padding: 16px 0;
    }

    h1 {
      padding-top: 0px;
      font-size: 26px;
      @include md-screen-up($screen-md) {
        font-size: 32px;
      }
    }

    h2,
    h3 {
      font-weight: 600;
    }

    h4 {
      font-size: 18px;
    }

    p {
      margin: 0;
      padding-bottom: 16px;
      white-space: pre-wrap;
    }

    figure {
      padding-bottom: 24px;
    }

    ul {
      list-style-type: disc;
      margin-top: 0;
      margin-left: 32px;
    }
  }
}
