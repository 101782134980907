.alert {
  @apply absolute w-full md:w-[400px] opacity-0 last-of-type:opacity-100 last-of-type:shadow-lg;
}

.count {
  position: absolute;
  right: -15px;
  top: -15px;
  &::before {
    content: attr(data-alert-count);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 32px;
    border: 1px solid #66737f;
    font-size: 14px;
    line-height: 18px;
    min-width: 30px;
    height: 30px;
  }
}
