.content {
  ul {
    @apply relative my-3 ml-12 mt-6;
  }

  li {
    @apply mt-3 flex items-center;

    &:after {
      content: ' ';
      @apply bg-black-900 absolute -left-6 h-1.5 w-1.5 rounded-full;
    }
  }

  p {
    @apply mt-6;
  }

  h2 {
    @apply text-md text-black-900 mt-6 font-bold;
  }

  h3 {
    @apply text-black-900 text-md my-6;
  }

  p {
    @apply text-black-600;
  }
}

.ctaBoxContent {
  p {
    @apply text-black-900 mt-0;
  }
}
