@import '@/styles/common/mixins';

.modal {
  flex: 1;
  max-width: 521px;
  margin: 15px;
  text-align: center;

  h1 {
    font-size: 32px;
  }
}

.content {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 40px;
  opacity: 1 !important;

  @include md-screen-down($screen-sm) {
    padding: 20px;
  }
}

.overlay {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 10050 !important;
  outline: 0 !important;
  background-color: #{theme('colors.black.900')}Ad;
  overflow: scroll !important;
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}
