.placeDetails {
  @apply z-9999 fixed bottom-[50px] flex min-h-[100px];
  width: 100%;
  flex-direction: column;
}
.carouselItem {
  @apply z-9999  left-4 right-4 min-h-[100px] p-2;
  -webkit-animation: fadein 200ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 200ms; /* Firefox  16 */
  -ms-animation: fadein 200ms; /* Internet Explorer */
  -o-animation: fadein 200ms; /* Opera < 12.1 */
  animation: fadein 200ms;
  display: flex;
  margin: 0px;
  direction: ltr;
  .carouselItemDetails {
    @apply rounded-lg bg-white;
    width: 100%;
  }
}
.carouselItem:first-child {
  margin-right: 0px !important;
}
.carouselItem:last-child {
  margin-left: 0px !important;
}

.cWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel {
  min-height: 100px;
  // max-height: 158px;

  direction: ltr;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
