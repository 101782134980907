.checkbox {
  @apply absolute opacity-0;
  &:disabled + label {
    @apply text-black-300 cursor-auto;
  }
  &:disabled + label:before {
    @apply shadow-none;
  }
  &:checked:disabled + label:before {
    @apply bg-checkbox-disabled bg-center bg-no-repeat;
  }
  &:checked:disabled + label {
    @apply text-black-900;
  }
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    width: 2px;
    height: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:checked + label:before {
    @apply bg-checkbox-checked bg-center bg-no-repeat;
  }
  :focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
  + label {
    @apply relative cursor-pointer p-0;
  }
  + label:before {
    content: '';
    @apply bg-checkbox-unchecked mr-2 inline-block h-5 w-5 bg-center bg-no-repeat align-middle;
  }
}
