$opacityTarget: 1;
$duration: 0.2s;
.show {
  opacity: 0;
  animation: fadeIn $duration linear 0s;
  animation-fill-mode: forwards;
}

.hide {
  //   opacity: $opacityTarget;
  animation: fadeOut $duration linear 0s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: $opacityTarget;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: $opacityTarget;
  }
}
