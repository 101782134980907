.rating {
  @apply relative inline-flex flex-row flex-wrap items-center justify-start;

  .stars {
    @apply flex cursor-pointer items-center;

    .count {
      @apply text-black-600 text-sm;
    }
  }
}

.starContainer {
  @apply bg-star-empty relative block h-3 w-3 bg-no-repeat;
  margin-right: 2px;
  background-size: 12px 12px;
}

.star {
  @apply bg-star-full absolute left-0 top-0 block h-3 w-3 bg-no-repeat;
  margin-right: 2px;
  background-size: 12px 12px;
}
