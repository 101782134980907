@import '../common/mixins';

body {
  display: none;
}

@for $i from 0 through 10 {
  .colgap-#{$i} {
    column-gap: $i * 4px;
  }
}

@for $i from 0 through 6 {
  .rot-z-#{$i*60} {
    transform: rotateZ($i * 60deg);
  }
}

@for $i from 0 through 6 {
  .rot-z-#{$i*45} {
    transform: rotateZ($i * 45deg);
  }
}

.container-fixed {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include md-screen-up($screen-sm) {
    max-width: 540px;
  }

  @include md-screen-up($screen-md) {
    max-width: 720px;
  }

  @include md-screen-up($screen-lg) {
    max-width: 960px;
  }

  @include md-screen-up($screen-xl) {
    max-width: 1140px;
  }
}

.h1-responsive {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;

  @include md-screen-up($screen-sm) {
    font-size: 32px;
    line-height: 35px;
  }
}

.text-h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
}

.text-h3 {
  font-size: 18px;
  line-height: 18px;
  padding: 0;
}

.unstyled-button {
  padding: 0;
  border: none;
  background-color: transparent;
}

.outline-none {
  &:focus {
    outline: none;
  }
  outline: none;
}

.splitter {
  border-bottom: 1px solid #f4f4f6;
  padding-bottom: 16px !important;
  margin-bottom: 16px !important;
}

h3.daypicker {
  max-width: 350px;
  margin: 0 auto;
  line-height: 19px;
  color: #2a2e43;
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 20px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
