.pin {
  input[data-valid='true']:focus {
    + label::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 68%;
      width: 2px;
      height: 65%;
      background: #000;
    }
  }
  input[data-valid='false']:focus {
    + label::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 48%;
      width: 2px;
      height: 65%;
      background: #000;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px theme('colors.black.100');
  }
}
