.modal {
  &:not(.floating) {
    .header {
      @apply px-lg;
    }
    .footer {
      @apply px-lg;
    }
  }
}
