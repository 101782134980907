.button {
  &.primary,
  &.secondary,
  &.tertiary {
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.2);
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.primary.invertIconColor,
  &.tertiary.invertIconColor {
    img {
      filter: invert(1);
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  &.lg {
    width: 24px;
    height: 24px;
  }
  &.md {
    width: 20px;
    height: 20px;
  }
  &.sm {
    width: 16px;
    height: 16px;
  }
  &.xs {
    width: 16px;
    height: 16px;
  }
}

@supports selector(:focus-visible) {
  .button {
    @apply focus-visible:outline-border_focus focus-visible:outline focus-visible:outline-4;
  }
}
