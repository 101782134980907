.statusMessage {
  @apply text-black-900 pointer-events-auto flex w-full items-center justify-between rounded border-[1px] px-4 py-4 text-sm;
}

.default {
  @apply border-black-600 bg-white;
}

.information {
  @apply border-information bg-information-50;
}
.success {
  @apply bg-success-50 border-success;
}
.warning {
  @apply border-warning bg-warning-50;
}
.error {
  @apply border-danger bg-danger-50;
}
