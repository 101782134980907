.blogContent h2 {
  @apply mt-4 text-2xl font-semibold;
}

.blogContent p {
  @apply mb-4 mt-2;
}

.blogContent h3 {
  @apply text-h-m mt-4 font-semibold;
}

.blogContent a {
  @apply text-secondary-500 hover:text-secondary-500;
}
