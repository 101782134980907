.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  &.h1,
  &.h2,
  &.h3,
  &.h4,
  &.h5,
  &.h6 {
    @apply mb-2;
  }

  * {
    @apply tracking-content;
  }

  h1,
  &.h1 {
    @apply text-3xl tracking-normal sm:text-5xl;
  }

  h2,
  &.h2 {
    @apply text-2xl tracking-normal sm:text-4xl;
  }

  h3,
  &.h3 {
    @apply text-h-m tracking-normal sm:text-3xl;
  }

  h4,
  &.h4 {
    @apply text-h-m tracking-normal sm:text-2xl;
  }

  h5,
  &.h5 {
    @apply text-h-m tracking-normal;
  }

  h6,
  &.h6 {
    @apply text-lg tracking-normal;
  }

  span,
  div,
  strong,
  em,
  b,
  i,
  &.base {
    @apply text-black-900;
  }

  .sm,
  &.sm {
    @apply text-sm;
  }

  strong,
  &.strong {
    @apply font-semibold;
  }

  small,
  .xs,
  &.xs {
    @apply text-xs;
  }
  .xxs,
  &.xxs {
    @apply text-xxs;
  }

  .md,
  &.md {
    @apply text-md;
  }
  .lg,
  &.lg {
    @apply text-lg;
  }
  .xl,
  &.xl {
    @apply text-xl;
  }

  a,
  &.a {
    @apply text-information-700 text-base underline;
  }
}

.content p,
.p {
  @apply text-black-900 mb-2 text-base;
}
