.modal-search input.search-input {
  padding-left: 44px;
  height: 44px;
  border-radius: 48px;
}

.modal-search .location-input {
  padding-left: 44px;
  height: 44px;
  border-radius: 48px;
}

.modal-search .location-input:not(:focus),
.modal-search .search-input:not(:focus) {
  @apply border-black-600;
}

.modal-search ul.location-autocomplete {
  margin-bottom: 8px;
  padding: 0;
  list-style: none;
}
.modal-search ul.q-autocomplete {
  margin: 0 0 8px 0;
}
.modal-search ul.q-autocomplete li,
.modal-search ul.location-autocomplete li {
  color: #2a2e43;
  font-size: 16px;
  line-height: 18px;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid #f4f4f6;
}
.modal-search ul.q-autocomplete li:last-of-type,
.modal-search ul.location-autocomplete li:last-of-type {
  border: none;
}

.modal-search ul.q-autocomplete li span.category-suggestion {
  background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
    no-repeat left center;
  padding-left: 29px;
}

.modal-search .q-container {
  width: 100%;
  display: block;
  z-index: 5;
  position: relative;
}

.modal-search ul.q-autocomplete li span.place-suggestion {
  position: relative;
  display: block;
}
.modal-search ul.q-autocomplete li span.place-suggestion .place-image {
  max-width: 20px;
  max-height: 20px;
}
.modal-search ul.q-autocomplete li span.place-suggestion span.place-label {
  margin-left: 10px;
  display: inline-block;
}
.modal-search .clear-search {
  position: absolute;
  display: block;
  right: 8px;
  top: 8px;
  width: 30px;
  height: 30px;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.448571 21.3855L9.83411 12L0.448571 2.61446C-0.149524 2.01637 -0.149524 1.04667 0.448571 0.448571C1.04667 -0.149524 2.01637 -0.149524 2.61446 0.448571L12 9.83411L21.3855 0.448571C21.9836 -0.149524 22.9533 -0.149524 23.5514 0.448571C24.1495 1.04667 24.1495 2.01637 23.5514 2.61446L14.1659 12L23.5514 21.3855C24.1495 21.9836 24.1495 22.9533 23.5514 23.5514C22.9533 24.1495 21.9836 24.1495 21.3855 23.5514L12 14.1659L2.61446 23.5514C2.01637 24.1495 1.04667 24.1495 0.448571 23.5514C-0.149524 22.9533 -0.149524 21.9836 0.448571 21.3855Z' fill='%23454F63'/%3E%3C/svg%3E ")
    no-repeat center center;
  background-size: 16px;
}
.modal-search .reset-location {
  position: absolute;
  display: block;
  right: 12px;
  top: 8px;
  width: 30px;
  height: 30px;
  background: #fff
    url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.448571 21.3855L9.83411 12L0.448571 2.61446C-0.149524 2.01637 -0.149524 1.04667 0.448571 0.448571C1.04667 -0.149524 2.01637 -0.149524 2.61446 0.448571L12 9.83411L21.3855 0.448571C21.9836 -0.149524 22.9533 -0.149524 23.5514 0.448571C24.1495 1.04667 24.1495 2.01637 23.5514 2.61446L14.1659 12L23.5514 21.3855C24.1495 21.9836 24.1495 22.9533 23.5514 23.5514C22.9533 24.1495 21.9836 24.1495 21.3855 23.5514L12 14.1659L2.61446 23.5514C2.01637 24.1495 1.04667 24.1495 0.448571 23.5514C-0.149524 22.9533 -0.149524 21.9836 0.448571 21.3855Z' fill='%23454F63'/%3E%3C/svg%3E ")
    no-repeat center center;
  background-size: 16px;
}

.search-container .date-time-container,
.search-container .q-container,
.search-container .location-container {
  display: block;
  z-index: 100;
  position: relative;
}

.search-container .form-control.location-input {
  border-left: none;
  border-right: none;
}

.search-container .list-page .date-time-container,
.search-container .list-page .location-container,
.search-container .list-page .q-container {
  width: 30%;
}

.search-container .home-page .date-time-container,
.search-container .home-page .location-container {
  margin-bottom: 8px;
  flex-grow: 1;
}

.search-container:not(.sistaminuten) .home-page .q-container {
  flex-grow: 1;
}

.search-container.sistaminuten .home-page .q-container,
.search-container.sistaminuten .home-page .location-container {
  flex-grow: 1;
}

.date-time-dropdown {
  margin-top: 58px;
}

.date-time-filters .react-calendar {
  @apply font-brown min-w-full border-none;

  &__navigation {
    @apply mx-auto flex items-center justify-between;
    max-width: 332px;
    button:focus,
    button:hover {
      @apply outline-primary-500 bg-transparent;
      outline-offset: 3px;
    }
    &__next-button,
    &__prev-button {
      @apply ring-primary-500 h-10 w-10 min-w-min rounded-full;
      &:not(:disabled) {
        @apply ring-1;
      }
      &:disabled {
        @apply text-black-300 bg-transparent;
      }
    }

    &__label {
      @apply capitalize;
    }
  }
}

.date-time-filters .react-calendar__year-view,
.date-time-filters .react-calendar__decade-view,
.date-time-filters .react-calendar__century-view {
  .react-calendar__tile {
    @apply outline-primary-500;
    &:disabled {
      @apply text-black-300 bg-transparent;
    }
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      @apply bg-primary-50;
    }
  }
}

.date-time-filters .react-calendar__month-view {
  &__days {
    @apply gap-y-1;
  }
  &__days__day--weekend:not(.react-calendar__tile--hasActive):not(:disabled) {
    @apply text-black-900;
  }
  &__days__day--weekend.react-calendar__tile--active:not(:disabled) {
    @apply text-white;
  }
  &__days__day--weekend:disabled {
    @apply bg-white;
  }
  &__weekdays {
    @apply text-black-600 text-center font-normal capitalize;

    abbr[title] {
      @apply select-none no-underline;
    }
  }

  &:not(.react-calendar--selectRange) {
    .react-calendar__tile {
    }
  }

  .react-calendar__tile {
    @apply h-12 w-12 select-none;

    &:focus {
      @apply outline-none;
    }
    &:disabled {
      @apply text-black-300 bg-white;
    }
    &:enabled:hover {
      @apply bg-primary-50 select-none;
    }

    &--hover {
      @apply bg-primary-50;
    }
    &--now:not(.react-calendar--selectRange) {
      @apply relative;
      overflow: visible !important;

      &:not(:enabled:hover):after,
      &:not(:enabled:focus)::after {
        content: '';
        @apply bg-primary absolute bottom-2 left-1/2 h-1 w-1 -translate-x-1/2 rounded-full;
      }
    }

    &--active,
    &--active:enabled:hover,
    &--active:enabled:focus {
      @apply bg-transparent;
    }
    &--hasActive,
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      @apply bg-transparent text-white;
    }

    &--range {
      @apply text-black-900 relative z-10 bg-transparent;
      &:before {
        @apply bg-primary-50 absolute bottom-1 left-0 right-0 top-1;
        content: '';
        z-index: -1;
      }
    }

    &--hasActive:not(.react-calendar__tile--range),
    &--hasActive:enabled:hover:not(.react-calendar__tile--range),
    &--hasActive:enabled:focus:not(.react-calendar__tile--range) {
      @apply relative z-20 h-12 w-12 select-none text-white;
      overflow: visible !important;
      abbr {
        @apply absolute bottom-0 left-0 right-0 top-0 flex h-12 w-12 items-center justify-center;
      }
      &:before {
        @apply bg-primary absolute bottom-0 left-0 right-0 top-0 h-12 w-12 rounded-full;
        content: '';
        z-index: -1;
      }
    }

    &--rangeStart,
    &--rangeEnd {
      @apply relative z-20 h-12 w-12 select-none text-white;
      overflow: visible !important;
      abbr {
        @apply absolute bottom-0 left-0 right-0 top-0 flex h-12 w-12 items-center justify-center;
        left: calc(50% - 1.5rem);
      }
      &:before {
        @apply bg-primary absolute bottom-0 left-0 right-0 top-0 h-12 w-12 rounded-full;
        content: '';
        z-index: -1;
        left: calc(50% - 1.5rem);
      }
      &:after {
        @apply bg-primary-50 absolute bottom-1 left-0 right-0 top-1;
        z-index: -2;
        content: '';
      }
    }
    &--rangeStart {
      &:after {
        left: 50%;
      }
    }
    &--rangeEnd {
      &:after {
        right: 50%;
      }
    }
  }
  &__weekNumbers {
    .react-calendar__tile {
      @apply text-black-600 text-m w-auto font-normal;
      line-height: 30px;
    }
  }
}

.date-time-filters .date-time-filter-underline {
  color: #d6dae2;
  background: #d6dae2;
  width: 90%;
  margin: 10px auto;
  border: none;
  height: 1px;
  opacity: 0.5;
}

.search-container .location-autocomplete-container,
.search-container .q-autocomplete-container {
  display: block;
  width: 100%;
  background-color: white;
  border: none;
  box-shadow: 0 3px 16px 3px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  max-height: 550px;
}

.search-container .location-autocomplete-container--open,
.search-container .q-autocomplete-container--open {
  border: 1px solid #d6dae2;
  overflow-y: auto;
}

.search-container .location-container ul.location-autocomplete {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  left: -1px;
  max-height: 550px;
  overflow-y: hidden;
}

.search-container .input-group input {
  @apply text-ellipsis;
  &.search-input {
    padding-left: 43px;
    height: 48px;
    background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
      no-repeat 16px center;
    background-size: 16px;
    background-color: #fff;
    width: 100%;
    border-radius: 40px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #2a2e43;

    &.source-home {
      height: 56px;
    }
  }

  &.search-input:not(.valid),
  &.location-input:not(.valid) {
    @apply border-black-600;
  }

  &.search-input.sistaminuten,
  &.location-input.sistaminuten {
    @apply border-sm_primary;
  }
}

.navigation.list-container .search-container .input-group input {
  border-width: 1px;
}
@media (min-width: 768px) {
  .navigation.list-container .search-container .input-group input {
    border-radius: 0px;
    &.search-input {
      border-bottom-left-radius: 9999px;
      border-top-left-radius: 9999px;
    }
    &.location-input {
      border-radius: 0;
    }
    &.date-time-input {
      border-radius: 0;
    }
  }
}

.search-container .input-group button[type='submit'] {
  max-width: 100px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.navigation .search-container .input-group {
  height: 48px;
  max-width: 910px;
  input.location-input {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4982 0C17.6356 0 22.2215 4.14531 21.9917 8.72743C21.769 13.7464 15.0669 24 12.4982 24C9.92944 24 3.22739 13.8586 3.00817 8.72743C2.78011 4.14531 7.3607 0 12.4982 0ZM12.4982 21.7107L13.5041 20.7283C14.4677 19.6194 15.3627 18.4536 16.1842 17.2373C18.0344 14.7196 19.2592 11.8038 19.7589 8.72743C19.8703 5.34522 16.3999 2.1823 12.4982 2.1823C8.59645 2.1823 5.12963 5.34522 5.23747 8.72743C5.71791 11.8092 6.94453 14.7293 8.81213 17.2373C9.59786 18.4791 10.4955 19.6475 11.494 20.7283C11.7717 21.1092 12.1117 21.4413 12.4999 21.7107H12.4982ZM12.4982 13.092C10.3665 13.0829 8.63159 11.3878 8.58938 9.27301C8.63461 7.16254 10.3717 5.47509 12.4991 5.47509C14.6264 5.47509 16.3635 7.16254 16.4087 9.27301C16.3665 11.3885 14.6305 13.0839 12.4982 13.092ZM12.4982 10.9097C13.4055 10.8953 14.1307 10.1562 14.121 9.25575C14.1113 8.35531 13.3704 7.63177 12.463 7.6365C11.5555 7.64127 10.8223 8.37252 10.8222 9.27301C10.8513 10.1749 11.5889 10.8952 12.4982 10.9097Z' fill='%23454F63'/%3E%3C/svg%3E ")
      no-repeat 16px center;
    background-size: 16px;

    &.valid {
      padding-right: 30px;
      text-overflow: ellipsis;
    }
  }

  input.search-input {
    &.valid {
      padding-right: 30px;
      text-overflow: ellipsis;
    }
  }

  input.location-input,
  input.date-time-input {
    width: 100%;
    padding-left: 40px;
    height: 48px;
    background-color: #fff;
    border-radius: 0;
    color: #2a2e43;

    &.source-home {
      height: 56px;
    }
  }
}

.navigation .location-container.error input.location-input {
  background: url("data:image/svg+xml,%3Csvg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4167 6C4.67292 6 0 10.2713 0 15.5215C0 18.9107 1.12794 20.2699 6.54813 26.8013C7.489 27.935 8.55545 29.22 9.77564 30.707C9.92698 30.8915 10.1645 31 10.4167 31C10.669 31 10.9064 30.8914 11.0577 30.7069C12.2708 29.2279 13.3326 27.9478 14.2694 26.8184C19.7027 20.268 20.8333 18.9049 20.8333 15.5215C20.8333 10.2713 16.1604 6 10.4167 6ZM12.9925 25.9333C12.223 26.861 11.3694 27.8901 10.4165 29.048C9.45705 27.8827 8.59861 26.8482 7.82489 25.9159C2.55256 19.5628 1.60256 18.418 1.60256 15.5215C1.60256 11.0791 5.55657 7.46484 10.4167 7.46484C15.2768 7.46484 19.2308 11.0791 19.2308 15.5215C19.2308 18.4124 18.2783 19.5606 12.9925 25.9333Z' fill='%23E43A35'/%3E%3Cpath d='M10.4176 10.3945C7.32478 10.3945 4.80859 12.6945 4.80859 15.5215C4.80859 18.3485 7.32478 20.6484 10.4176 20.6484C13.5104 20.6484 16.0265 18.3485 16.0265 15.5215C16.0265 12.6945 13.5104 10.3945 10.4176 10.3945ZM10.4176 19.1836C8.20843 19.1836 6.41116 17.5408 6.41116 15.5215C6.41116 13.5022 8.20843 11.8594 10.4176 11.8594C12.6267 11.8594 14.424 13.5022 14.424 15.5215C14.424 17.5408 12.6267 19.1836 10.4176 19.1836Z' fill='%23E43A35'/%3E%3Cpath d='M20 20C25.5228 20 30 15.5228 30 10C30 4.47715 25.5228 0 20 0C14.4772 0 10 4.47715 10 10C10 15.5228 14.4772 20 20 20Z' fill='%23E43A35'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 6.01375L21.0137 10L25 13.9863L23.9863 15L20 11.0137L16.0137 15L15 13.9863L18.9863 10L15 6.01375L16.0137 5L20 8.98625L23.9863 5L25 6.01375Z' fill='white'/%3E%3C/svg%3E ")
    no-repeat 15px 5px;
  background-size: 23px 24px;
  color: #e43a35;
}
.navigation .location-container.loading input.location-input {
  @apply bg-loading-icon bg-no-repeat;
  background-position: 12px center;
  background-color: #fff;
  background-size: 20px 20px;
}

.location-container ul.location-autocomplete.hidden {
  display: none;
}

.location-container ul.location-autocomplete li#react-autowhatever-location-autosuggest--item-0 span {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5uYXZpZ2F0aW9uPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9Im5hdmlnYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1Ljg2MDQxOCwgOC44MzY5ODEpIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0xNS44NjA0MTgsIC04LjgzNjk4MSkgdHJhbnNsYXRlKDUuODYwNDE4LCAtMi42NjMwMTkpIiBmaWxsPSIjMzFBQ0ZGIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTguOTgzODk0NSwyMi4wODU4Mzk4IEwxMC4xMzc2OTkyLDAuNDEyNzQyMTg4IEMxMC4wMzU4MTY0LDAuMTYzMTExMzI4IDkuNzkzMDEzNjcsMCA5LjUyMzQzNzUsMCBDOS4yNTM4NjEzMywwIDkuMDExMDU4NTksMC4xNjMxMTEzMjggOC45MDkxNzU3OCwwLjQxMjc0MjE4OCBMMC4wNjMwMjUzOTA2LDIyLjA4NTgzOTggQy0wLjA0NTc3NTM5MDYsMjIuMzUyMzYxMyAwLjAyOTczODI4MTMsMjIuNjU4NjM4NyAwLjI0OTk0NTMxMywyMi44NDQwNzYyIEMwLjQ3MDE1MjM0NCwyMy4wMjkzNzg5IDAuNzg0NzQwMjM0LDIzLjA1MTc1IDEuMDI4OTM1NTUsMjIuODk5MTk1MyBMOS41MjM0Mzc1LDE3LjU5MDEwMzUgTDE4LjAxNzkzOTUsMjIuODk5MTUwNCBDMTguMTI2MDY2NCwyMi45NjY3NTc4IDE4LjI0Nzk4NDQsMjMgMTguMzY5NDUzMSwyMyBDMTguNTIyMjMyNCwyMyAxOC42NzQyOTMsMjIuOTQ3MzUxNiAxOC43OTY5Mjk3LDIyLjg0NDAzMTMgQzE5LjAxNzE4MTYsMjIuNjU4NjM4NyAxOS4wOTI2OTUzLDIyLjM1MjMxNjQgMTguOTgzODk0NSwyMi4wODU4Mzk4IFoiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 14px center;
  background-size: 18px;
}

.location-container ul.location-autocomplete li {
  padding: 12px 0 12px 4px;
  cursor: pointer;
  background-size: 16px;
  background-color: #fff;
}

.location-container ul.location-autocomplete li span {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.4982 0C17.6356 0 22.2215 4.14531 21.9917 8.72743C21.769 13.7464 15.0669 24 12.4982 24C9.92944 24 3.22739 13.8586 3.00817 8.72743C2.78011 4.14531 7.3607 0 12.4982 0ZM12.4982 21.7107L13.5041 20.7283C14.4677 19.6194 15.3627 18.4536 16.1842 17.2373C18.0344 14.7196 19.2592 11.8038 19.7589 8.72743C19.8703 5.34522 16.3999 2.1823 12.4982 2.1823C8.59645 2.1823 5.12963 5.34522 5.23747 8.72743C5.71791 11.8092 6.94453 14.7293 8.81213 17.2373C9.59786 18.4791 10.4955 19.6475 11.494 20.7283C11.7717 21.1092 12.1117 21.4413 12.4999 21.7107H12.4982ZM12.4982 13.092C10.3665 13.0829 8.63159 11.3878 8.58938 9.27301C8.63461 7.16254 10.3717 5.47509 12.4991 5.47509C14.6264 5.47509 16.3635 7.16254 16.4087 9.27301C16.3665 11.3885 14.6305 13.0839 12.4982 13.092ZM12.4982 10.9097C13.4055 10.8953 14.1307 10.1562 14.121 9.25575C14.1113 8.35531 13.3704 7.63177 12.463 7.6365C11.5555 7.64127 10.8223 8.37252 10.8222 9.27301C10.8513 10.1749 11.5889 10.8952 12.4982 10.9097Z' fill='%23454F63'/%3E%3C/svg%3E ");
  background-repeat: no-repeat;
  background-position: 14px center;
  padding-left: 46px;
  background-size: 16px;
}

.location-container ul.location-autocomplete li span.geolocation {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4334_33122)'%3E%3Cpath d='M12.2053 22.1345L19.4331 4.93728C19.5163 4.73921 19.4714 4.51048 19.3195 4.35858C19.1676 4.20667 18.9389 4.16177 18.7408 4.24502L1.54357 11.4729C1.33208 11.5617 1.20205 11.7769 1.22164 12.0054C1.24131 12.2339 1.40597 12.4238 1.62954 12.4754L9.4077 14.2704L11.2027 22.0485C11.2255 22.1476 11.2755 22.235 11.3439 22.3034C11.43 22.3895 11.5453 22.4455 11.6727 22.4564C11.9012 22.4761 12.1164 22.346 12.2053 22.1345Z' fill='%2331ACFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4334_33122'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E ");
}

.location-container ul.location-autocomplete li:hover,
.location-container ul.location-autocomplete li.react-autosuggest__suggestion--highlighted,
.search-container ul.q-autocomplete li:hover,
.search-container ul.q-autocomplete li.react-autosuggest__suggestion--highlighted {
  @apply bg-black-100;
}

.modal-search .location-container ul.location-autocomplete li:hover,
.modal-search .location-container ul.location-autocomplete li.react-autosuggest__suggestion--highlighted,
.modal-search .search-container ul.q-autocomplete li:hover,
.modal-search .search-container ul.q-autocomplete li.react-autosuggest__suggestion--highlighted {
  @apply bg-transparent;
}

.location-container ul.location-autocomplete li.title {
  cursor: auto;
  padding: 0 0 0 13px;
  font-size: 14px;
  color: #777;
}
.location-container ul.location-autocomplete li.current {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5uYXZpZ2F0aW9uPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9Im5hdmlnYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1Ljg2MDQxOCwgOC44MzY5ODEpIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0xNS44NjA0MTgsIC04LjgzNjk4MSkgdHJhbnNsYXRlKDUuODYwNDE4LCAtMi42NjMwMTkpIiBmaWxsPSIjMzFBQ0ZGIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTguOTgzODk0NSwyMi4wODU4Mzk4IEwxMC4xMzc2OTkyLDAuNDEyNzQyMTg4IEMxMC4wMzU4MTY0LDAuMTYzMTExMzI4IDkuNzkzMDEzNjcsMCA5LjUyMzQzNzUsMCBDOS4yNTM4NjEzMywwIDkuMDExMDU4NTksMC4xNjMxMTEzMjggOC45MDkxNzU3OCwwLjQxMjc0MjE4OCBMMC4wNjMwMjUzOTA2LDIyLjA4NTgzOTggQy0wLjA0NTc3NTM5MDYsMjIuMzUyMzYxMyAwLjAyOTczODI4MTMsMjIuNjU4NjM4NyAwLjI0OTk0NTMxMywyMi44NDQwNzYyIEMwLjQ3MDE1MjM0NCwyMy4wMjkzNzg5IDAuNzg0NzQwMjM0LDIzLjA1MTc1IDEuMDI4OTM1NTUsMjIuODk5MTk1MyBMOS41MjM0Mzc1LDE3LjU5MDEwMzUgTDE4LjAxNzkzOTUsMjIuODk5MTUwNCBDMTguMTI2MDY2NCwyMi45NjY3NTc4IDE4LjI0Nzk4NDQsMjMgMTguMzY5NDUzMSwyMyBDMTguNTIyMjMyNCwyMyAxOC42NzQyOTMsMjIuOTQ3MzUxNiAxOC43OTY5Mjk3LDIyLjg0NDAzMTMgQzE5LjAxNzE4MTYsMjIuNjU4NjM4NyAxOS4wOTI2OTUzLDIyLjM1MjMxNjQgMTguOTgzODk0NSwyMi4wODU4Mzk4IFoiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 35px;
  background-size: 18px;
}

.modal-search ul.location-autocomplete li {
  padding-top: 16px;
  padding-bottom: 16px;
}
.location-container ul.location-autocomplete li.current.error {
  color: #e43a35;
}

.location-container ul.location-autocomplete li.title:hover {
  background-color: white;
}

.q-autocomplete-container,
.location-autocomplete-container {
  margin-top: 6px;
  display: block;
  width: 100%;
  z-index: 100;
  border-radius: 12px;
}

.q-container .q-autocomplete.hidden {
  display: none;
}

.q-autocomplete-container .go-to-favorites {
  display: none;
}
.q-autocomplete-container--open .go-to-favorites {
  display: flex;
}

.q-container .q-autocomplete li.title:hover {
  background-color: white;
  border-radius: 8px;
}
.q-container .q-autocomplete li .place-image {
  max-height: 25px;
  max-width: 25px;
  overflow: hidden;
  display: inline-block;
}
.q-container .q-autocomplete li .place-label {
  padding-left: 10px;
  display: inline-block;
  height: 25px;
}

.q-container ul.q-autocomplete li span.history-suggestion {
  display: block;
  width: 100%;
  padding-left: 29px;
  background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
    no-repeat left center;
  background-size: 15px 15px;
}
.q-container ul.q-autocomplete li span.category-suggestion {
  padding-left: 32px;
  background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
    no-repeat 0px center;
  background-size: 15px 15px;
}
.q-container ul.q-autocomplete li span.spelling-suggestion {
  padding-left: 32px;
  background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
    no-repeat 0px center;
  background-size: 15px 15px;
}
.q-container ul.q-autocomplete li:hover,
.q-container ul.q-autocomplete li.active {
  background-color: #f5f6f8;
}
.q-container ul.q-autocomplete li.title {
  cursor: auto;
  padding: 0 0 0 13px;
  font-size: 14px;
  color: #777;
}
.q-container ul.q-autocomplete li.current {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5uYXZpZ2F0aW9uPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9Im5hdmlnYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1Ljg2MDQxOCwgOC44MzY5ODEpIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0xNS44NjA0MTgsIC04LjgzNjk4MSkgdHJhbnNsYXRlKDUuODYwNDE4LCAtMi42NjMwMTkpIiBmaWxsPSIjMzFBQ0ZGIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTguOTgzODk0NSwyMi4wODU4Mzk4IEwxMC4xMzc2OTkyLDAuNDEyNzQyMTg4IEMxMC4wMzU4MTY0LDAuMTYzMTExMzI4IDkuNzkzMDEzNjcsMCA5LjUyMzQzNzUsMCBDOS4yNTM4NjEzMywwIDkuMDExMDU4NTksMC4xNjMxMTEzMjggOC45MDkxNzU3OCwwLjQxMjc0MjE4OCBMMC4wNjMwMjUzOTA2LDIyLjA4NTgzOTggQy0wLjA0NTc3NTM5MDYsMjIuMzUyMzYxMyAwLjAyOTczODI4MTMsMjIuNjU4NjM4NyAwLjI0OTk0NTMxMywyMi44NDQwNzYyIEMwLjQ3MDE1MjM0NCwyMy4wMjkzNzg5IDAuNzg0NzQwMjM0LDIzLjA1MTc1IDEuMDI4OTM1NTUsMjIuODk5MTk1MyBMOS41MjM0Mzc1LDE3LjU5MDEwMzUgTDE4LjAxNzkzOTUsMjIuODk5MTUwNCBDMTguMTI2MDY2NCwyMi45NjY3NTc4IDE4LjI0Nzk4NDQsMjMgMTguMzY5NDUzMSwyMyBDMTguNTIyMjMyNCwyMyAxOC42NzQyOTMsMjIuOTQ3MzUxNiAxOC43OTY5Mjk3LDIyLjg0NDAzMTMgQzE5LjAxNzE4MTYsMjIuNjU4NjM4NyAxOS4wOTI2OTUzLDIyLjM1MjMxNjQgMTguOTgzODk0NSwyMi4wODU4Mzk4IFoiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 35px;
  background-size: 18px;
}
.q-container ul.q-autocomplete li.title:hover {
  background-color: white;
  border-radius: 8px;
}

.q-container .q-autocomplete li {
  padding: 5px 0 5px 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.q-container .q-autocomplete li:hover {
  background-color: #f5f6f8;
}
.q-container .q-autocomplete .highlight {
  background-color: #f5f6f8;
}
.q-container .q-autocomplete .highlight:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.q-container .q-autocomplete .highlight:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.q-container .q-autocomplete li.title {
  cursor: auto;
  padding: 0 0 0 13px;
  font-size: 14px;
  color: #777;
}
.q-container .q-autocomplete li.current {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5uYXZpZ2F0aW9uPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9Im5hdmlnYXRpb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE1Ljg2MDQxOCwgOC44MzY5ODEpIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC0xNS44NjA0MTgsIC04LjgzNjk4MSkgdHJhbnNsYXRlKDUuODYwNDE4LCAtMi42NjMwMTkpIiBmaWxsPSIjMzFBQ0ZGIj4gICAgICAgICAgICA8cGF0aCBkPSJNMTguOTgzODk0NSwyMi4wODU4Mzk4IEwxMC4xMzc2OTkyLDAuNDEyNzQyMTg4IEMxMC4wMzU4MTY0LDAuMTYzMTExMzI4IDkuNzkzMDEzNjcsMCA5LjUyMzQzNzUsMCBDOS4yNTM4NjEzMywwIDkuMDExMDU4NTksMC4xNjMxMTEzMjggOC45MDkxNzU3OCwwLjQxMjc0MjE4OCBMMC4wNjMwMjUzOTA2LDIyLjA4NTgzOTggQy0wLjA0NTc3NTM5MDYsMjIuMzUyMzYxMyAwLjAyOTczODI4MTMsMjIuNjU4NjM4NyAwLjI0OTk0NTMxMywyMi44NDQwNzYyIEMwLjQ3MDE1MjM0NCwyMy4wMjkzNzg5IDAuNzg0NzQwMjM0LDIzLjA1MTc1IDEuMDI4OTM1NTUsMjIuODk5MTk1MyBMOS41MjM0Mzc1LDE3LjU5MDEwMzUgTDE4LjAxNzkzOTUsMjIuODk5MTUwNCBDMTguMTI2MDY2NCwyMi45NjY3NTc4IDE4LjI0Nzk4NDQsMjMgMTguMzY5NDUzMSwyMyBDMTguNTIyMjMyNCwyMyAxOC42NzQyOTMsMjIuOTQ3MzUxNiAxOC43OTY5Mjk3LDIyLjg0NDAzMTMgQzE5LjAxNzE4MTYsMjIuNjU4NjM4NyAxOS4wOTI2OTUzLDIyLjM1MjMxNjQgMTguOTgzODk0NSwyMi4wODU4Mzk4IFoiIGlkPSJQYXRoIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 35px;
  background-size: 18px;
}

.q-container ul.q-autocomplete li {
  @apply border-black-100 border-b last-of-type:border-b-0;

  padding: 12px 12px 12px 13px;
  cursor: pointer;
}
.q-container .suggestions-section {
  padding: 12px 0 12px 13px;
}

.q-autocomplete li span.history-suggestion {
  width: 100%;
  display: block;
  background: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5996 20.1992C5.29789 20.1992 1 15.9013 1 10.5996C1 5.29789 5.29789 1 10.5996 1C15.9013 1 20.1992 5.29789 20.1992 10.5996C20.1992 15.9013 15.9013 20.1992 10.5996 20.1992ZM17.7998 17.7998L25 25L17.7998 17.7998Z' stroke='black' stroke-width='1.5' stroke-linejoin='round'/%3E%3C/svg%3E ")
    no-repeat left center;
  padding-left: 29px;
}

.suggest h3 {
  color: #78849e;
  font-size: 12px;
  font-weight: 600;
  margin-top: 24px;
}
.suggest ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
.suggest ul li {
  line-height: 54px;
  border-bottom: 1px solid #f4f4f6;
  padding-left: 24px;
}
.suggest.searches ul li {
  padding-left: 24px;
}

.suggest.searches li .place-image {
  height: 25px;
  width: 25px;
  overflow: hidden;
  display: inline-block;
}
.suggest.searches li .place-label {
  padding-left: 10px;
  display: inline-block;
  height: 25px;
}
