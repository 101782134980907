.Toastify__toast-container {
  z-index: 10;
  position: fixed;
  padding: 4px;
  width: 100%;
  max-width: theme('screens.md');

  &--bottom-left {
    bottom: 0;
    left: 1em;
  }
  &--bottom-center {
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: 0;
    right: 1em;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    padding: 0;
    left: 0;
    margin: 0;
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }
    &--rtl {
      right: 0;
      left: initial;
    }
  }
}
