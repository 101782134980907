$screen-xs: 388px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

@mixin md-screen-up($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin md-screen-down($breakpoint) {
  $max-width: $breakpoint - 1px;
  @media (max-width: $max-width) {
    @content;
  }
}
