.overlay {
  @apply fixed bottom-0 left-0 right-0 top-0 overflow-scroll outline-0;
}

.overlayBackground {
  background-color: rgba(0, 0, 0, 0.7);
}

.dialog {
  @apply pointer-events-none flex min-h-full min-w-full justify-center sm:min-h-0;
}

.content {
  @apply pointer-events-auto absolute bottom-0 left-0 right-0 top-0 mx-auto flex h-auto max-w-full flex-1 flex-col overflow-y-scroll bg-white bg-clip-padding outline-none sm:bottom-auto sm:mx-4 sm:my-8 sm:flex-initial sm:overflow-auto sm:rounded-lg;
  &.sm {
    @apply sm:w-modal-sm mx-auto;
  }
  &.md {
    @apply sm:w-modal-md mx-auto;
  }
  &.lg {
    @apply sm:w-modal-lg mx-auto;
  }
  &.fullscreen {
    @apply mx-0 my-0 h-full w-full rounded-none;
  }

  &.stickToBottom {
    @apply top-auto;
    max-height: 640px;
  }

  &.stickToBottomRight {
    @apply bottom-0 left-auto right-0 top-auto mx-0 my-0 rounded-none;
    max-height: 640px;
    width: min(500px, 100%);
  }
}

.actionbar {
  @apply z-10000 fixed bottom-0 right-0 flex h-auto w-full flex-col items-center justify-center bg-white px-6 py-4 text-sm shadow;
  padding-bottom: calc(32px + env(safe-area-inset-bottom));
}
