@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInBottom {
  animation: slideInFromBottom 0.2s ease-out forwards;
}
