.tag-highlight {
  border-radius: 1px;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  background-color: theme('colors.highlight.DEFAULT');
}

.tag-warning {
  border-radius: 1px;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  background-color: theme('colors.warning.DEFAULT');
}
