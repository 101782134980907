.steps {
  @apply pointer-events-auto relative z-10 flex h-5 flex-row items-center justify-center;

  .step {
    @apply relative flex flex-col items-center justify-center;

    .number {
      @apply bg-black-700 text-m flex h-5 w-5 select-none items-center justify-center rounded-lg text-center text-white;

      &.disabled {
        @apply bg-black-400;
      }
      &.active {
        box-shadow: 0 3px 12px 0 rgba(69, 91, 99, 0.15);
        border-radius: 50%;
      }
    }
  }
}
