.button {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &.lg {
    width: 56px;
    height: 56px;
  }

  &.md {
    width: 48px;
    height: 48px;
  }
  &.sm {
    width: 40px;
    height: 40px;
  }
  &.xs {
    width: 32px;
    height: 32px;
  }

  &.primary,
  &.tertiary {
    img {
      filter: invert(1);
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    &.lg {
      width: 32px;
      height: 32px;
    }
    &.md,
    &.sm {
      width: 24px;
      height: 24px;
    }
    &.xs {
      width: 20px;
      height: 20px;
    }
  }
}
@supports selector(:focus-visible) {
  .button {
    &:focus-visible {
      outline: 4px solid rgba(81, 164, 146, 0.4);
    }
  }
}
