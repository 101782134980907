.placePinRating {
  position: absolute;
  left: 0;
  right: 0;
  top: 15;
  text-align: center;
  font-family: 'Gibson';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
}
.placePinImage {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 15;
  right: 0;
}
.placePinActive {
  z-index: 9999 !important;

  .placePinRating {
    font-size: 16px;
    line-height: 40px;
  }
}
