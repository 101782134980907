@import '@/styles/common/mixins';

.button {
  &.large {
    width: 100%;
  }

  &.small {
    padding-top: 11px;
    padding-bottom: 11px;
    width: 99px;

    @include md-screen-up($screen-sm) {
      width: 105px;
    }

    @include md-screen-up($screen-lg) {
      margin-right: 16px !important;
    }

    @include md-screen-down($screen-xs) {
      margin-bottom: 26px !important;
    }
  }
}
