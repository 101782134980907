
.pageHeader {
  @apply flex h-40 items-center bg-template-page-cover bg-no-repeat bg-cover bg-right;
  background-color: #ddd;
}

.cookiesContent {
  @apply mt-4;
  
  .cookiesList {
    @apply list-none m-0;

    li {
      @apply py-3 ml-0;
      border-bottom: 1px solid #efefef;

      &:first-of-type {
        border-top: 1px solid #efefef;
      }
      &:last-of-type {
        border: none;
      }
    }
  }
}
