li {
  &.phone {
    @apply bg-call-icon bg-left bg-no-repeat;
  }
  &.email {
    @apply bg-mail-icon bg-left bg-no-repeat;
  }
  &.address {
    @apply bg-location-icon bg-left bg-no-repeat;
  }
}
