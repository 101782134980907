.Banner {
  &__image {
    width: 130px;

    @media (min-width: 340px) {
      width: 163px;
    }
    @media (min-width: 768px) {
      width: 396px;
    }
  }
}
