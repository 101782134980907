.fieldContainer {
  &.half {
    flex: 1;
  }

  &.full {
    width: 100%;
  }
}

.field {
  position: relative;
  input {
    font-size: 16px;
    &.hasValue {
      border-color: theme('colors.black.600');
    }

    &.error {
      border-color: theme('colors.danger.DEFAULT');
    }

    &::placeholder {
      color: theme('colors.black.600');
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:not(.active) {
      ::placeholder {
        transform: translateY(-10px);
      }
    }

    &.active {
      border-color: theme('colors.primary.DEFAULT');

      &::placeholder {
        opacity: 1;
      }
    }
  }
}

span.error {
  font-size: 12px;
  color: theme('colors.danger.DEFAULT');
  padding-left: 10px;
  position: relative;
  display: inline-block;

  &:before {
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: theme('colors.danger.DEFAULT');
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.label {
  position: absolute;
  user-select: none;
  cursor: text;
  font-size: 16px;
  border-radius: 100%;
  color: #757575 !important;
  height: 20px;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  transition: top 0.5s;
  padding: 0 4px;
  margin: 0;
  margin-top: 2px;
  background-color: white;
  z-index: 10;
  display: inline-flex;
  align-items: center;

  &.active {
    top: 0;
    user-select: all;
    color: theme('colors.black.900') !important;
    pointer-events: none;
  }
}
