.adyen :global(.adyen-checkout__label__text) {
  color: #78849e !important;
  font-size: 15px !important;
}

.adyen :global(.adyen-checkout__input--focus),
:global(.adyen-checkout__input--focus:hover),
:global(.adyen-checkout__input:focus),
:global(.adyen-checkout__input:focus:hover) {
  border: 1px solid #23a994 !important;
  box-shadow: none !important;
}

.adyen :global(.adyen-checkout__card__brands__brand-wrapper:after) {
  top: 4px !important;
}

.adyen :global(.adyen-checkout__card__brands img) {
  top: 4px !important;
  position: relative;
}
