.card {
  animation: appear 1s;
  &:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 2s 0.5s;
    background: linear-gradient(90deg, #0000 33%, rgba(255, 255, 255, 0.6) 50%, #0000 66%);
    background-size: 300% 100%;
  }
}

@keyframes slide {
  0% {
    background-position: right;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
