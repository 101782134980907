@import '../common/mixins';

.padded-input {
  padding: 14px;
  line-height: 14px;
  padding-bottom: 12px;
}

.button-focus,
.button {
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.focus,
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
  }
}

.button-focus-primary {
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 169, 148, 0.5);
  }
}

.button {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  border: 0;
  line-height: 30px !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px !important;

  &-lg {
    padding: 18px 24px;
    line-height: 16px;
    font-size: 16px;

    @include md-screen-down($screen-sm) {
      display: block;
      width: 100%;
    }
  }
}

.button-lg-responsive {
  padding: 10px 24px;

  @include md-screen-down($screen-md) {
    padding: 10px 18px;
  }
}

.button-block {
  display: block;
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  line-height: 13px;
}

.button-primary {
  background-color: #23a994;
  transition: background-color 0.3s ease-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 169, 148, 0.5);
  }
}

.button-primary-outline {
  border: 2px solid theme('colors.primary.DEFAULT');
  color: theme('colors.primary.DEFAULT');
  background-color: theme('colors.white');
}

.button-primary:hover,
.button-primary-outline:hover,
.button-primary:not([disabled]):not(.disabled).active,
.button-primary:not([disabled]):not(.disabled):active {
  background: #23a994;
  color: #fff;
}

.button:disabled {
  cursor: default !important;
}

.form-group label {
  font-size: theme('fontSize.base');
  color: theme('colors.black.600');
  line-height: 17px;
  margin-bottom: 8px;
}

.form-control:focus,
.form-control.valid {
  border-color: #23a994;
  outline: none !important;
  box-shadow: none !important;
}
.form-control.sistaminuten:focus,
.form-control.sistaminuten.valid {
  border-color: theme('colors.sm_primary.DEFAULT');
  outline: none !important;
  box-shadow: none !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 1rem;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group-btn {
  position: relative;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;
}
.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.input-group-btn:not(:first-child) > .btn-group:first-child,
.input-group-btn:not(:first-child) > .btn:first-child {
  margin-left: -1px;
}
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn,
.input-group-btn:not(:last-child) > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
