.date-time-picker-calendar.react-calendar {
  @apply font-brown min-w-full border-none;
}

.react-calendar__navigation {
  @apply mx-auto flex items-center justify-between;
  max-width: 332px;
  button:focus,
  button:hover {
    @apply bg-transparent;
    outline-offset: 3px;
  }
  button:disabled {
    @apply bg-transparent;
  }

  &__next-button,
  &__prev-button {
    @apply ring-black-100 h-10 w-10 min-w-min rounded-full bg-transparent;
    &:hover {
      @apply bg-transparent;
    }
    &:not(:disabled) {
      @apply ring-1;
    }
    &:disabled {
      visibility: hidden;
    }
  }

  &__label {
    @apply capitalize;
  }
}

.date-time-picker-calendar .react-calendar__year-view,
.date-time-picker-calendar .react-calendar__decade-view,
.date-time-picker-calendar .react-calendar__century-view {
  .react-calendar__tile {
    @apply outline-primary-500;
    &:disabled {
      @apply text-black-300 bg-transparent;
    }
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      @apply bg-primary-50;
    }
  }
}

.date-time-picker-calendar .react-calendar__month-view {
  &__days {
    @apply gap-y-1;
  }
  &__days__day--weekend:not(.react-calendar__tile--hasActive):not(:disabled) {
    @apply text-black-900;
  }
  &__days__day--weekend.react-calendar__tile--active:not(:disabled) {
    @apply text-white;
  }
  &__days__day--weekend:disabled {
    @apply bg-white;
  }
  &__weekdays {
    @apply text-black-600 text-center font-normal capitalize;

    abbr[title] {
      @apply select-none no-underline;
    }
  }

  .react-calendar__tile {
    @apply h-12 w-12 select-none;

    &:focus {
      @apply outline-none;
    }
    &:disabled {
      @apply text-black-300 bg-white;
    }
    &:enabled:hover {
      @apply bg-primary-50 select-none;
    }

    &--hover {
      @apply bg-primary-50;
    }

    &--now:not(.react-calendar--selectRange) {
      @apply relative bg-transparent;
      overflow: visible !important;

      &:not(:enabled:hover):after,
      &:not(:enabled:focus)::after {
        content: '';
        @apply bg-primary absolute bottom-2 left-1/2 h-1 w-1 -translate-x-1/2 rounded-full;
      }
    }

    &--active,
    &--active:enabled:hover,
    &--active:enabled:focus {
      @apply bg-transparent;
    }
    &--hasActive,
    &--hasActive:enabled:hover,
    &--hasActive:enabled:focus {
      @apply bg-transparent text-white;
    }

    &--range {
      @apply text-black-900 relative z-10 bg-transparent;
      &:before {
        @apply bg-black-100 absolute bottom-1 left-0 right-0 top-1;
        content: '';
        z-index: -1;
      }
    }

    &--hasActive:not(.react-calendar__tile--range),
    &--hasActive:enabled:hover:not(.react-calendar__tile--range),
    &--hasActive:enabled:focus:not(.react-calendar__tile--range) {
      @apply relative z-20 h-12 w-12 select-none text-white;
      overflow: visible !important;
      abbr {
        @apply absolute bottom-0 left-0 right-0 top-0 flex h-12 w-12 items-center justify-center;
      }
      &:before {
        @apply bg-primary absolute bottom-0 left-0 right-0 top-0 h-12 w-12 rounded-full;
        content: '';
        z-index: -1;
      }
    }

    &--rangeStart,
    &--rangeEnd {
      @apply relative z-20 h-12 w-12 select-none text-white;
      overflow: visible !important;
      abbr {
        @apply absolute bottom-0 left-0 right-0 top-0 flex h-12 w-12 items-center justify-center;
        left: calc(50% - 1.5rem);
      }
      &:before {
        @apply bg-primary absolute bottom-0 left-0 right-0 top-0 h-12 w-12 rounded-full;
        content: '';
        z-index: -1;
        left: calc(50% - 1.5rem);
      }
      &:after {
        @apply bg-black-100 absolute bottom-1 left-0 right-0 top-1;
        z-index: -2;
        content: '';
      }
    }
    &--rangeStart {
      &:after {
        left: 50%;
      }
    }
    &--rangeEnd {
      &:after {
        right: 50%;
      }
    }
  }
  &__weekNumbers {
    .react-calendar__tile {
      @apply text-black-600 text-m w-auto font-normal;
      line-height: 30px;
    }
  }
}
