.slick-calendar {
  display: flex;
  padding: 0px;
  flex-direction: row;
  margin-bottom: 0px;
  overflow: hidden;
  position: relative;
}

.slick-list {
  width: 100%;
}
.slick-track {
  display: flex;
}

.slick-list,
.slick-track {
  touch-action: pan-y;
}
