@mixin transform {
  transform: translate3d(0, 0, 0);
}

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    @include transform;
  }
}

@keyframes Toastify__slideOutDown {
  from {
    @include transform;
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

.Toastify__slide-enter {
  &--bottom-center {
    animation-name: Toastify__slideInUp;
  }
}

.Toastify__slide-exit {
  &--bottom-center {
    animation-name: Toastify__slideOutDown;
  }
}
