.active {
  @apply border-transparent text-black-600;
  &:hover {
    border-color: #51A49266;
  }

  .pgbar {
    @apply bg-black-100;

    .pgbarval {
      @apply bg-warning-500;
    }
  }
}

.disabled {
  @apply border-transparent text-black-200;
  &:hover {
    border-color: #51A49266;
  }
  .pgbar {
    @apply bg-black-50;

    .pgbarval {
      @apply bg-black-200;
    }
  }
}