.generalTermsContent {
  @apply py-lg px-xxl gap-md flex flex-col text-sm;

  p {
    @apply whitespace-pre-wrap;
  }

  h2 {
    @apply text-xl;
    font-weight: 600;
  }

  h3 {
    @apply text-sm;
    font-weight: 600;
  }

  strong {
    font-weight: 600;
  }

  ul {
    @apply pl-xxl;
    list-style-type: disc;
  }

  li {
    @apply py-sm;
  }

  a {
    @apply text-information-700 underline;
  }
}
